* {
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
}

div[linkinbiotheme] {
	height: 100%;
}

:root,
[linkinbiotheme='theme1'] {
	--linkBackground: #ebeef1;
	--linkText: #000;
	--linkHoverBackground: #ebeef1;
	--linkHoverText: #000;
	--linkRadius: 14px;
	--profileBackground: #fff;
	--socialIconColor: #000;
	/* --BackgroundImage: url('https://images.pexels.com/photos/1939485/pexels-photo-1939485.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'); */
}

:root,
[linkinbiotheme='theme2'] {
	--linkBackground: #43e660;
	--linkText: #1e2330;
	--linkHoverBackground: #43e660;
	--linkHoverText: #1e2330;
	--linkRadius: 14px;
	--profileBackground: #fff;
	--socialIconColor: #000;
	--BackgroundImage: null;
}

:root,
[linkinbiotheme='theme3'] {
	--linkBackground: #2665d6;
	--linkText: #fff;
	--linkHoverBackground: #2665d6;
	--linkHoverText: #fff;
	--linkRadius: 14px;
	--profileBackground: #fff;
	--socialIconColor: #000;
	--BackgroundImage: null;
}

:root,
[linkinbiotheme='theme4'] {
	--linkBackground: #2a3235;
	--linkText: #fff;
	--linkHoverBackground: #2a3235;
	--linkHoverText: #fff;
	--linkRadius: 14px;
	--profileBackground: #fff;
	--socialIconColor: #000;
	--BackgroundImage: null;
}

:root,
[linkinbiotheme='theme5'] {
	--linkBackground: #fff;
	--linkText: #000;
	--linkHoverBackground: #fff;
	--linkHoverText: #000;
	--linkRadius: 14px;
	--profileBackground: #ebeef1;
	--socialIconColor: #000;
	--BackgroundImage: null;
}

:root,
[linkinbiotheme='theme6'] {
	--linkBackground: #ebeef1;
	--linkText: #000;
	--linkHoverBackground: #ebeef1;
	--linkHoverText: #000;
	--linkRadius: 14px;
	--profileBackground: #fff;
	--socialIconColor: #000;
	/* --BackgroundImage: url('https://images.pexels.com/photos/1939485/pexels-photo-1939485.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'); */
}

.main-container {
	display: flex;
	flex: 1 1 0%;
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 0px 16px 32px;
	width: 100%;
	height: 100%;
	margin: 0px auto;
}

.main-container_two {
	display: flex;
	flex: 1 1 0%;
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	margin: 0px auto;
}

.main-container_three {
	display: flex;
	flex: 1 1 0%;
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	margin: 0px auto;
}

.main-container_four {
	display: flex;
	flex: 1 1 0%;
	flex-direction: column;
	-webkit-box-pack: justify;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	margin: 0px auto;
}

.theme-main-container {
	margin: 0px auto;
	width: 100%;
	height: 100%;
	max-width: 680px;
}

.profile_container {
	margin: 0px auto;
	width: 100%;
	padding-top: 64px;
	max-width: 680px;
}

.profile_container_two {
	padding: 70px 0px;
	max-width: 680px;
	position: relative;
	text-align: center;
}

.profile_container_two::after {
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	z-index: -1;
	background-size: cover;
	background-position: center;
	opacity: 0.9;
}

.profile_container_three {
	padding: 70px 0px;
	max-width: 680px;
	position: relative;
}

.profile_container_three::after {
	content: '';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	z-index: -1;
	background-size: cover;
	background-position: center;
	opacity: 0.9;
}

.profile_container_four {
	padding: 60px 0px;
	max-width: 680px;
	position: relative;
}

.profile_container_four::after {
	content: '';
	top: 10px;
	left: 10px;
	right: 10px;
	bottom: 10px;
	border-radius: 10px;
	position: absolute;
	z-index: -1;
	background-size: cover;
	background-position: center;
	opacity: 0.9;
}

.divsly_brand {
	display: flex;
	margin: 0px auto;
	padding: 10px 10px;
	border-radius: 25px;
	margin-top: 32px;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	background-color: #fff;
	backdrop-filter: blur(20px);
	width: fit-content;
}

.profile_name {
	margin: 0px;
	color: rgb(0, 0, 0);
	font-weight: 700;
	font-size: 20px;
	line-height: 1.5;
	text-overflow: ellipsis;
	max-width: 100%;
	overflow: hidden;
	z-index: 10;
}

.profile_description {
	padding: 0px;
	margin: 0px;
	text-align: center;
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
}

@media screen and (min-width: 576px) {
	.profile_description {
		font-size: 16px;
	}
}

.buttons_list {
	list-style: none;
	width: 100%;
	margin-top: 32px;
}

.link_button {
	z-index: 0;
	overflow: hidden;
	margin-bottom: 16px;
	border: none;
	transition: transform 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
	border-radius: 14px;
	border: none;
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
	text-align: center;
	cursor: pointer;
	background: none;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
	position: relative;
	padding: 16px 20px;
	width: 100%;
	appearance: none;
	box-sizing: border-box;
	vertical-align: middle;
}

.link_button:hover {
	transform: translateY(2px);
}

.link_text {
	text-decoration: none;
	padding: 0px;
	margin: 0px;
	line-height: 1.5;
	width: 100%;
	font-weight: 500;
	font-size: 14px;
	color: initial;
}

.profile_img img {
	border-radius: 50%;
	width: 96px;
	height: 96px;
	display: block;
	object-fit: contain;
	object-position: initial;
	filter: none;
}

.profile_img_four img {
	border-radius: 50%;
	width: 96px;
	height: 96px;
	display: block;
	object-fit: contain;
	object-position: initial;
	filter: none;
	margin: 0px auto;
}

.profile_img_two img {
	border-radius: 50%;
	width: 96px;
	height: 96px;
	display: block;
	object-fit: contain;
	object-position: initial;
	filter: none;
}

.profile_img_two {
	position: absolute;
	top: -50px;
	z-index: 1;
}
.profile_img_three img {
	border-radius: 50%;
	width: 96px;
	height: 96px;
	display: block;
	object-fit: contain;
	object-position: initial;
	filter: none;
}

.profile_img_three {
	position: absolute;
	left: 10px;
	top: -50px;
	z-index: 1;
}

.profile_content {
	padding-top: 64px;
}

.profile_content_three {
	padding-top: 32px;
	padding-left: 100px;
	width: 100%;
}

.social_icons {
	display: flex;
	margin: 0px auto;
	margin-top: 15px;
	justify-content: center;
	gap: 16px;
}
.social_icons_three {
	display: flex;
	margin-top: 15px;
	justify-content: center;
	gap: 16px;
}

.social_icon {
	font-size: 20px;
}

/* .bio_background
{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #2665D6;
    background-image: url("");
}

@media screen and (min-width: 576px) {
    .bio_background {
        background-image:url("");
    }
}

@media screen and (min-width: 767px) {
    .bio_background {
        background-image:url("");
    }
} */

div[linkinbiotheme] .link_button {
	background-color: var(--linkBackground);
	border-radius: var(--linkRadius);
	transition:
		background-color 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s,
		box-shadow 0.15s cubic-bezier(0, 0.2, 0.5, 3) 0s;
}

div[linkinbiotheme] .link_text {
	color: var(--linkText);
}

div[linkinbiotheme] .link_button:hover {
	background-color: var(--linkHoverBackground);
}

div[linkinbiotheme] .link_text:hover {
	color: var(--linkHoverText);
}

div[linkinbiotheme] .social_icon {
	color: var(--socialIconColor);
}

div[linkinbiotheme] {
	background-color: var(--profileBackground);
}

div[linkinbiotheme].layoutone {
	background-image: var(--BackgroundImage);
	background-repeat: no-repeat;
	background-size: cover;
}
/* 
div[linkinbiotheme] .profile_container_two::after
{
    background-image: var(--BackgroundImage);
    z-index: 1;

} */
div[linkinbiotheme] .profile_container_two_after {
	background-image: var(--BackgroundImage);
	z-index: 1;
}

/* div[linkinbiotheme] .profile_container_three::after
{
    background-image: var(--BackgroundImage);
    z-index: 1;

} */

div[linkinbiotheme] .profile_container_three_after {
	background-image: var(--BackgroundImage);
	z-index: 1;
}

/* div[linkinbiotheme] .profile_container_four::after
{
    background-image: var(--BackgroundImage);
    z-index: 1;
} */

div[linkinbiotheme] .profile_container_four_after {
	background-image: var(--BackgroundImage);
	z-index: 1;
}

.desktop .profile_content_three .profile_description {
	width: 27rem !important;
}
