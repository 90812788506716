.card-container.qr-codes-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	gap: 20px;
	padding: 20px 0px;
}

.qr-codes-list .card {
	background-color: #fff;
	border-radius: 10px;
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	gap: 15px;
	padding: 15px;
	transition: transform 0.2s;
}

.qr-codes-list .card:hover {
	border: 1px solid #7239ea !important;
}

.qr-codes-list .card-icon {
	margin-right: 15px;
	margin-top: 5px;
}

.qr-codes-list .card-title {
	font-size: 18px;
	font-weight: bold;
}

.qr-codes-list .card-description {
	font-size: 14px;
	color: #555;
	margin-bottom: 0px;
}

.card-qr-view {
	margin-left: auto;
}

.card-qr-view:hover i {
	color: #7239ea;
}

.card-qr-view i {
	color: #000 !important;
}

/* The switch container */
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

/* Hide default checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
	border-radius: 34px;
}

.slider:before {
	position: absolute;
	content: '';
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	transition: 0.4s;
	border-radius: 50%;
}

/* Checked state */
input:checked + .slider {
	background-color: #2196f3;
}

input:checked + .slider:before {
	transform: translateX(26px);
}
