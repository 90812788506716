.crop-container {
	position: relative;
	width: 100%;
	height: 400px;
	background-color: #333;
	display: flex;
	justify-content: center;
	align-items: center;
}

.controls {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.controls button {
	margin: 0 10px;
}
