body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.fiftyWidthHeight {
	width: 30px;
	height: 30px;
}

.customDashboardSize {
	width: 100%;
}

.heightWidthTwenty {
	width: 20px;
	height: 20px;
}

.customTextWidth {
	width: 152px;
}

.tableMinWidth {
	min-width: 990px;
}

/* @media only screen and (min-width: 992px) {
  .customDashboardSize {
    width: calc(100% - 300px);
  }
} */

@font-face {
	font-family: 'Anton';
	src: url('/src/assets/fonts/static/Anton/Anton-Regular.ttf');
	font-weight: 100;
}

@font-face {
	font-family: 'Itim';
	src: url('/src/assets/fonts/static/Itim/Itim-Regular.ttf');
	font-weight: 100;
}

@font-face {
	font-family: 'Oswald';
	src: url('/src/assets/fonts/static/Oswald/static/Oswald-Bold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: 'Oswald';
	src: url('/src/assets/fonts/static/Oswald/static/Oswald-ExtraLight.ttf');
	font-weight: 200;
}

@font-face {
	font-family: 'Oswald';
	src: url('/src/assets/fonts/static/Oswald/static/Oswald-Light.ttf');
	font-weight: 300;
}

@font-face {
	font-family: 'Oswald';
	src: url('/src/assets/fonts/static/Oswald/static/Oswald-Medium.ttf');
	font-weight: 500;
}

@font-face {
	font-family: 'Oswald';
	src: url('/src/assets/fonts/static/Oswald/static/Oswald-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'Oswald';
	src: url('/src/assets/fonts/static/Oswald/static/Oswald-SemiBold.ttf');
	font-weight: 600;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-Black.ttf');
	font-weight: 900;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-BlackItalic.ttf');
	font-weight: 900;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-Bold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-BoldItalic.ttf');
	font-weight: 700;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-Italic.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-Light.ttf');
	font-weight: 300;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-LightItalic.ttf');
	font-weight: 300;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-Medium.ttf');
	font-weight: 500;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-MediumItalic.ttf');
	font-weight: 500;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-Thin.ttf');
	font-weight: 100;
}

@font-face {
	font-family: 'Roboto';
	src: url('/src/assets/fonts/static/Roboto/Roboto-ThinItalic.ttf');
	font-weight: 100;
}

.PhoneInput .PhoneInputInput {
	border: transparent;
	outline: none;
	font-weight: 500;
	font-size: 1.1rem;
	color: #5e6278;
}

.PhoneInputCountryIcon--border {
	box-shadow: none !important;
}
